<template>
  <div>
    <nav class="header_TRS">
      <div class="header__container">
        <div class="menu_left">
          <a href="#">
            <img
              src="/logo_check.png"
              alt="arrow-left"
            >
          </a>
        </div>

        <div class="menu_right">
          <a
            href="#"
            class="btn_download"
          >ดาวน์โหลดฟรี</a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
